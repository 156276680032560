.container {
  height: 100vh;
  width: 100%;
  background: url("/images/auth-bg.jpg");
}

.right-container {
  height: 100vh;
  background-color: white;
  -webkit-box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.65);
  box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.65);
  padding: 20px;
  padding-top: 10%;
}
.left-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.left-container * {
  color: white;
}
