.form-file-input .MuiFormLabel-root.Mui-focused {
  color: grey !important;
}
.form-file-input
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
}

.fb-form-header .logo-wrapper {
  text-align: right !important;
}

@media only screen and (max-width: 768px) {
  .fb-form-header {
    flex-direction: column-reverse !important;
    text-align: center !important;
  }

  .fb-form-header .logo-wrapper {
    text-align: center !important;
  }
}
